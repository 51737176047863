import {
	isValidCNPJ,
	isValidCPF,
	isValidCEP,
	isValidPhone,
	isValidEmail,
} from '@brazilian-utils/brazilian-utils'

export const obrigatorio = (valor: any) => {
	if (typeof valor === 'boolean') { return true }
	return !!valor || 'Obrigatório'
}
export const positivo = (valor: number) => {
	if( valor < 0) return 'Valor de abertura inválido'  
}
export const emailInvalido = (valor: string) =>
	!valor || isValidEmail(valor) || 'E-mail inválido'

export const cnpjInvalido = (valor: string) =>
	!valor || isValidCNPJ(valor) || 'CNPJ inválido'

export const cpfInvalido = (valor: string) =>
	!valor || isValidCPF(valor) || 'CPF inválido'

export const cepInvalido = (valor: string) =>
	!valor || isValidCEP(valor) || 'CEP inválido'

export const cnpjCpfInvalido = (valor: string) =>
	!valor || isValidCNPJ(valor) || isValidCPF(valor) || 'CNPJ / CPF inválido'

export const tempoParaLogoffInvalido = (valor: string) =>
	valor != null && valor.length < 9 || 'Tempo inválido'

export const tempoLimiteDeInatividadeInvalido = (valor: string) =>
	valor != null && valor.length < 9 || 'Tempo inválido'

export const cnpjCpfValido = (valor: string) =>
	!isValidCNPJ(valor) || !isValidCPF(valor)

export const telefoneInvalido = (valor: string) =>
	!valor || isValidPhone(valor) || 'Telefone inválido'

export const maiorQueZero = (valor: number) => 
	valor > 0 || 'Deve ser maior que zero'

export const menorQueZero = (valor: number) => 
	valor < 0 || 'Deve ser negativo'

export const valorInteiro = (valor: number) =>
	valor === Math.floor(valor) || 'Valor não pode ser fracionado'

export const maxDigit = (tamanho: number) => (valor: number | string | null) =>
	(valor !== null && valor.toString().length <= tamanho) || `Qtde máxima: ${tamanho} dígitos`

export const naoPermiteEmail = (valor: string) =>
	!isValidEmail(valor) || 'Este campo não permite um e-mail'

export const maiorOuIgualAZero = (valor: number) => 
	valor >= 0 || 'Deve ser um valor maior ou igual a zero'


export const arrayObrigatorio = (valor: any) => {
	if (typeof valor === 'boolean') {return true }
	if (!valor || !valor.length) return 'Obrigatório selecionar um valor'
	return true
}

export const isValorNumerico = (value: string) => {
	const numericPattern = /^\d*$/;
	return numericPattern.test(value) || 'Insira apenas números';
};

export const maiorOuIgualAUm = (valor: number) => 
	valor >= 1 || 'Deve ser um valor maior ou igual a um'

export const validarCampoObrigatorio = (valor: any) => {
	return valor === '' ? 'Campo obrigatório' : true
}